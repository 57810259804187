<template>
  <!-- DISCOUNTS MODAL -->
  <b-modal id="modal-discounts" ref="modal-discounts" size="xl" scrollable :hide-footer="!canEdit || !canAdd" @hidden="$emit('clearDiscountsPriceProject')">
    <template #modal-header>
      <h4 v-if="modalName === 'project'">{{ $t('table.projectDiscounts') }}</h4>
      <h4 v-else-if="modalName === 'order'">{{ $t('table.orderDiscounts') }}</h4>
      <h4 v-else>{{ $t('table.mediaPlanDiscounts') }}</h4>
      <b-icon font-scale="1.75" icon="x" style="cursor: pointer" @click="hideModalDiscounts"></b-icon>
    </template>

    <template #default>
      <SpinnerLoader v-if="isLoadingModal" :loading="isLoadingModal === true ? 'loading' : 'success'" />
      <form v-else @submit.stop.prevent="checkIfValidAddThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <div v-if="canEdit || canAdd" class="d-flex justify-content-end align-items-center pb-2">
          <b-button :class="modalName === 'mediaPlan' ? 'btn-add-mr' : ''" data-automatic="add-row" @click="addRowInModal">
            {{ $t('table.addRow') }}
          </b-button>
        </div>
        <div v-if="pricesMode && priceProject && Object.keys(priceProject).length !== 0" class="mb-3">
          <label class="font-weight-bold">{{ $t('table.priceProject') }}:</label> {{ priceProject.name }}
          <!-- SHOW FOR FIXED GRP -->
          <template v-if="priceProject.channel_project_price_project_by_grp_details && priceProject.channel_project_price_project_by_grp_details.length > 0">
            <table>
              <thead>
                <tr>
                  <th>{{ priceProject.channel_name }}</th>
                  <th class="pl-2">{{ $t('channelModal.prime') }}</th>
                  <th class="pl-2">{{ $t('channelModal.offPrime') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dt, dtIndex) in priceProject.channel_project_price_project_by_grp_details" :key="dtIndex">
                  <th>{{ dt.measurement_company_name }} – {{ dt.target_audience_name }}</th>
                  <td class="pl-2">{{ dt.prime_cost | spaceNumber }}</td>
                  <td class="pl-2">{{ dt.off_prime_cost | spaceNumber }}</td>
                </tr>
              </tbody>
              <tfoot v-if="mediaPlanPlacementTypeId && (mediaPlanPlacementTypeId === 3 || mediaPlanPlacementTypeId === 4)" class="pt-3">
                <tr v-if="mediaPlanPlacementTypeId === 3 && priceProject.auto_placement_type_discount">
                  <th class="pt-2">{{ $t('table.discountForAutomatic') }}</th>
                  <td class="pt-2 pl-4" colspan="2">{{ priceProject.auto_placement_type_discount }}%</td>
                </tr>
                <tr v-else-if="mediaPlanPlacementTypeId === 4 && priceProject.low_priority_type_discount">
                  <th class="pt-2">{{ $t('table.discountForLowPriority') }}</th>
                  <td class="pt-2 pl-4" colspan="2">{{ priceProject.low_priority_type_discount }}%</td>
                </tr>
                <tr v-if="priceProject.co_branding_discount != null">
                  <th class="pt-2">{{ $t('table.surchargeForCoBrand') }}</th>
                  <td class="pt-2 pl-4" colspan="2">{{ priceProject.co_branding_discount }}%</td>
                </tr>
              </tfoot>
            </table>
          </template>
          <!-- SHOW FOR TIME SLOTS -->
          <template v-else-if="priceProject.channel_project_price_project_by_time_details">
            <table>
              <thead>
                <tr>
                  <th colspan="2">{{ priceProject.channel_name }}</th>
                  <th class="pl-4">{{ $t('table.price') }}</th>
                </tr>
                <tr v-if="priceProject.co_branding_discount != null">
                  <th class="pt-2">{{ $t('table.surchargeForCoBrand') }}</th>
                  <td class="pt-2 pl-4" colspan="2">{{ priceProject.co_branding_discount }}%</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dt, dtIndex) in priceProject.channel_project_price_project_by_time_details" :key="dtIndex">
                  <th>{{ returnDays(dt.days_of_week_iso) }}</th>
                  <th class="pl-4">{{ sec2time(dt.start_interval) }} - {{ sec2time(dt.end_interval) }}</th>
                  <td class="pl-4">{{ dt.price | spaceNumber }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>

        <div class="d-flex gap-2" :class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'">
          <div class="w-100">
            <div class="d-flex wrapper-table-project-settings small">
              <label style="width: 28%">{{ $t('sideBar.discountTypes') }}</label>
              <label style="width: 30%">{{ $t('table.period') }}</label>
              <label style="width: 8%">%</label>
              <label style="width: 10%">{{ $t('channelModal.channel') }}</label>
              <label style="width: 16%">{{ $t('channelModal.from') }}</label>
            </div>
            <draggable v-model="discounts" ghost-class="outline-danger" :disabled="modalName != 'project'">
              <div v-for="(disc, index) in discounts" :key="index" class="d-flex wrapper-table-project-settings horizontal-form-centered">
                <b-form-group style="width: 28%">
                  <MultiSelect
                    v-model="discounts[index].discount_type_id"
                    class="multiselect-sm"
                    :options="discountsFilteredList"
                    :placeholder="$t('table.selectDiscount')"
                    label="name"
                    track-by="id"
                    :disabled="!!discounts[index].id"
                    data-automatic="ms-discount"
                    @input="setDiscountData(index)"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <BIconExclamationTriangle
                        v-if="props.option.type === 2"
                        class="text-danger"
                        :title="$t('table.specialDiscount') + '. ' + $t('table.appliesInReportsOnly')"
                      />
                      <IconRB
                        v-if="props.option.type === 3"
                        class="text-danger d-inline-block"
                        style="width: 16px"
                        :title="$t('RBDiscounts.retroBonusDiscounts')"
                      />

                      {{ props.option.name }}
                    </template>
                    <template slot="option" slot-scope="props">
                      <BIconExclamationTriangle
                        v-if="props.option.type === 2"
                        class="text-danger"
                        :title="$t('table.specialDiscount') + '. ' + $t('table.appliesInReportsOnly')"
                      />
                      <IconRB v-if="props.option.type === 3" class="text-danger d-inline-block" :title="$t('RBDiscounts.retroBonusDiscounts')" />
                      {{ props.option.name }}
                    </template>
                  </MultiSelect>
                </b-form-group>

                <b-form-group style="width: 30%" class="d-flex period">
                  <datepicker-wrapper
                    v-model="discounts[index].from"
                    required
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :disabled="(!!discounts[index].id && modalName != 'project') || discounts[index].discount_type_id.type == 3"
                    boundary="viewport"
                    style="width: 50%"
                    size="sm"
                    data-automatic="date-from"
                  />
                  <datepicker-wrapper
                    v-model="discounts[index].to"
                    required
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    :disabled="(!!discounts[index].id && modalName != 'project') || discounts[index].discount_type_id.type == 3"
                    :min="discounts[index].from"
                    boundary="viewport"
                    style="width: 48%"
                    size="sm"
                    data-automatic="date-to"
                  />
                </b-form-group>

                <b-form-group style="width: 8%">
                  <b-form-input
                    v-model.number="discounts[index].percent"
                    class="mr-4"
                    type="number"
                    placeholder="%"
                    required
                    min="-100"
                    max="100"
                    step="any"
                    size="sm"
                    :disabled="!!discounts[index].id && modalName != 'project'"
                    data-automatic="input-percent"
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 10%">
                  <b-form-input
                    class="mr-4"
                    type="text"
                    required
                    size="sm"
                    :value="discounts[index].channel ? discounts[index].channel.name : ''"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 12%">
                  <b-form-input v-model="discounts[index].fromType" class="mr-4" type="text" required size="sm" disabled></b-form-input>
                </b-form-group>

                <div class="tb tb-m" style="text-align: center; flex-basis: 4%">
                  <template v-if="canEdit || canDelete">
                    <b-icon
                      v-if="!discounts[index].id"
                      class="trash-hover cursor-pointer"
                      icon="trash"
                      :title="$t('table.delete')"
                      @click="removeRow(index)"
                    ></b-icon>
                    <b-icon
                      v-else-if="discounts[index].id && discounts[index].fromType === type && canDelete"
                      class="trash-hover cursor-pointer"
                      icon="trash"
                      :title="$t('table.delete')"
                      @click="deleteRow(index)"
                    ></b-icon>
                  </template>
                </div>
              </div>
            </draggable>
          </div>
          <div
            v-if="modalName === 'mediaPlan' || modalName === 'project'"
            style="flex-basis: 140px"
            class="border-left pl-2"
            :class="isThemeHeader === 'true' ? 'border-secondary' : ''"
          >
            <b-form-group
              v-for="(pos, index) in positions"
              :key="index"
              :label="positions[index].name"
              :label-for="`input-pos-${index}`"
              label-cols="4"
              :class="index === 2 ? 'mb-4' : ''"
              label-size="sm"
            >
              <b-form-input
                :id="`input-pos-${index}`"
                v-model.number="positions[index].value"
                type="number"
                required
                min="-100"
                max="100"
                step="any"
                :placeholder="positions[index].placeholder.toString()"
                size="sm"
                :disabled="!canEdit || !canAdd"
              ></b-form-input>
            </b-form-group>
            <b-form-group v-if="modalName === 'mediaPlan'" :label="$t('table.surchargeForCoBrand')" label-for="input-cobrand-surcharge" label-size="sm">
              <b-form-input
                id="input-cobrand-surcharge"
                v-model="coBrandingDiscountValue"
                type="number"
                min="0"
                max="100"
                step="any"
                placeholder="0 — 100%"
                size="sm"
                :disabled="!canEdit || !canAdd"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </form>
    </template>

    <template #modal-footer>
      <b-button variant="outline-danger mr-3" @click="hideModalDiscounts">
        {{ $t('table.close') }}
      </b-button>
      <!-- <b-button variant="danger" :disabled="$v.$invalid || isLoadingModal || !isAllSDOrdersSet" @click="Discounts"></b-button> -->
      <b-button variant="danger" :disabled="$v.$invalid || isLoadingModal || !percentsValid" @click="Discounts">
        {{ modalDiscountsType === 'add' ? $t('table.add') : $t('table.save') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import MultiSelect from '@/components/MultiSelect';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import errorsHandler from '@/utils/errorsHandler';
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import spaceNumber from '@/filters/spaceNumber';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';
import { BIconExclamationTriangle } from 'bootstrap-vue';
import timeConvertHelpers from '@/mixins/timeConvertHelpers';
import draggable from 'vuedraggable';
import IconRB from '@/components/icons/IconRB';

export default {
  name: 'ModalDiscounts',
  components: { MultiSelect, SpinnerLoader, DatepickerWrapper, BIconExclamationTriangle, draggable, IconRB },
  filters: { spaceNumber },
  mixins: [timeConvertHelpers],
  props: {
    isOpenModal: { type: Boolean },
    modalName: { type: String, default: undefined },
    modalDiscountsType: { type: String, default: 'add' },
    discountsEditList: { type: Array, default: undefined },
    premiumDiscountsEditList: { type: Object, default: undefined },
    canEdit: { type: Boolean },
    canAdd: { type: Boolean },
    canDelete: { type: Boolean },
    priceProject: { type: Object, default: undefined },
    pricesMode: { type: Boolean },
    channels_id_list: { type: Array, default: undefined },
    date_to: { type: String, default: undefined },
    date_from: { type: String, default: undefined },
    mediaPlanPlacementTypeId: { type: Number, default: undefined },
    projectDiscounts: { type: Array, default: undefined },
    projectId: { type: Number, default: undefined },
    orderDiscounts: { type: Array, default: undefined },
    coBrandingDiscount: { type: [Number, String], default: null },
  },
  data() {
    let typeFrom = '';
    if (this.modalName === 'project') {
      typeFrom = 'Project';
    } else if (this.modalName === 'order') {
      typeFrom = 'Order';
    } else {
      typeFrom = 'Media Plan';
    }

    return {
      type: typeFrom,
      filterDateFrom: '',
      filterDateTo: '',
      positions: [
        {
          name: '1F',
          value: '',
          placeholder: '%',
        },
        {
          name: '2F',
          value: '',
          placeholder: '%',
        },
        {
          name: '3F',
          value: '',
          placeholder: '%',
        },
        {
          name: '3L',
          value: '',
          placeholder: '%',
        },
        {
          name: '2L',
          value: '',
          placeholder: '%',
        },
        {
          name: '1L',
          value: '',
          placeholder: '%',
        },
      ],
      discounts: [
        {
          discount_type_id: '',
          from: '',
          to: '',
          percent: '',
          fromType: typeFrom,
          channel: '',
          order: null,
        },
      ],
      discountsFilteredList: [],
      isLoadingModal: false,
      dragging: null,
    };
  },
  validations() {
    if (this.discounts && this.discounts.length > 0) {
      return {
        discounts: {
          required,
          $each: {
            discount_type_id: { required },
            from: { required },
            to: { required },
            percent: { required },
          },
        },
      };
    } else {
      return {};
    }
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      discountTypes: 'getDiscountTypes',
      premiumPositionDiscounts: 'getPremiumPositionDiscounts',
      projectPremiumPositionDiscounts: 'getProjectPremiumPositionDiscounts',
      channelsList: 'getChannelsList',
    }),
    //disables Submit btn if there is any unset order for Special discount
    isAllSDOrdersSet() {
      //if no discounts
      if (!(this.discounts && this.discounts.length > 0)) return true;
      const SDs = this.discounts.filter((e) => !e.id && e.discount_type_id?.type === 3);
      // if no SD
      if (SDs.length === 0) return true;
      // true if all SD orders is set
      return !SDs.some((e) => !e.order);
    },

    coBrandingDiscountValue: {
      get() {
        return this.coBrandingDiscount;
      },
      set(value) {
        this.$emit('update:coBrandingDiscount', value);
      },
    },

    percentsValid() {
      let res = true;
      for (const element of this.discounts) {
        let min = -100;
        let max = 100;
        if (element.discount_type_id.type == 3) {
          min = -500;
          max = 500;
        }
        if (element.percent < min || element.percent > max) {
          res = false;
          return res;
        }
      }
      return res;
    },
  },
  watch: {
    async isOpenModal() {
      if (this.isOpenModal) {
        this.isLoadingModal = true;
        this.$refs['modal-discounts'].show();
        this.clearFields();
        if (this.date_to && this.date_from) {
          this.filterDateFrom = this.date_from.slice(0, 4) + '-01-01';
          this.filterDateTo = this.date_to.slice(0, 4) + '-12-31';
        }
        this.discountsFilteredList = [];
        await Promise.all([
          !this.channelsList || (this.channelsList && this.channelsList.length < 1) ? this.$store.dispatch('GET_CHANNELS', { per_page: 1000 }) : undefined,
          this.$store.dispatch('GET_DISCOUNT_TYPES', {
            per_page: 1000,
            'filter[from]': this.filterDateFrom || null,
            'filter[to]': this.filterDateTo || null,
            'filter[channel_ids]': this.channels_id_list,
          }),
        ]);
        if (this.discountTypes) {
          const date_start = new Date(this.date_from).getTime();
          const date_end = new Date(this.date_to).getTime();
          this.discountTypes.data.forEach((el) => {
            const discount_from = new Date(el.from).getTime();
            const discount_to = new Date(el.to).getTime();
            //only push discounts that cover object period partly of fully
            if ((discount_from <= date_start && discount_to >= date_start) || (discount_from >= date_start && discount_from <= date_end))
              if (el.type === 1) this.discountsFilteredList.push(el);
              else if (el.type === 2 && this.modalName === 'project') this.discountsFilteredList.push(el); // push special discounts in multiselect list only for Project discounts modal
          });
        }
        // project-order discounts

        if (this.modalName !== 'project') {
          this.removeRow(0);
        }

        if (this.modalName === 'order') {
          this.pushDiscounts(this.projectDiscounts, 'Project');
        } else if (this.modalName === 'mediaPlan') {
          this.pushDiscounts(this.projectDiscounts, 'Project');
          this.pushDiscounts(this.orderDiscounts, 'Order');
        }
        if (this.modalName === 'mediaPlan' || this.modalName === 'project') {
          if (this.premiumDiscountsEditList) {
            this.positions.forEach((position, index) => {
              if (this.premiumDiscountsEditList[position.name] !== undefined) {
                this.positions[+index].value = this.premiumDiscountsEditList[position.name];
              }
            });
          }
        }

        //If data from parent edit modal
        if (this.discountsEditList) {
          if (this.modalName === 'project') {
            this.removeRow(0);
          }
          this.pushDiscounts(this.discountsEditList);
        }

        if (this.type === 'Media Plan' && !(this.premiumDiscountsEditList && Object.keys(this.premiumDiscountsEditList).length !== 0)) {
          //only for ADD MP type
          await this.$store.dispatch('GET_PREMIUM_POSITION_DISCOUNTS', {
            per_page: 6,
            'filter[from]': this.filterDateFrom || null,
            'filter[to]': this.filterDateTo || null,
          });
          await this.$store.dispatch('GET_PROJECT_PREMIUM_POSITION_DISCOUNTS', { id: this.projectId });
          if (this.premiumPositionDiscounts) {
            this.premiumPositionDiscounts.data.forEach((el) => {
              for (var i = 0; i < this.positions.length; i++) {
                if (this.positions[i].name === el.name) {
                  this.positions[i].value = el.percent;
                }
              }
            });
          }
          if (this.projectPremiumPositionDiscounts) {
            this.projectPremiumPositionDiscounts.data.forEach((el) => {
              for (var i = 0; i < this.positions.length; i++) {
                if (this.positions[i].name === el.name) {
                  this.positions[i].value = el.percent;
                }
              }
            });
          }
        } else if (this.type === 'Project') {
          //only for Project type
          await this.$store.dispatch('GET_PREMIUM_POSITION_DISCOUNTS', {
            per_page: 6,
            'filter[from]': this.filterDateFrom || null,
            'filter[to]': this.filterDateTo || null,
          });
          if (this.premiumPositionDiscounts) {
            this.premiumPositionDiscounts.data.forEach((el) => {
              for (var i = 0; i < this.positions.length; i++) {
                if (this.positions[i].name === el.name) {
                  this.positions[i].placeholder = el.percent;
                }
              }
            });
          }
        }
        this.isLoadingModal = false;
        this.$emit('isOpenModal', false);
      }
    },
  },
  methods: {
    // Set discount data on select in data, percent fields
    setDiscountData(index) {
      if (this.discounts[index].discount_type_id) {
        this.discounts[index].from = this.discounts[index].discount_type_id.from;
        this.discounts[index].to = this.discounts[index].discount_type_id.to;
        this.discounts[index].percent = this.discounts[index].discount_type_id.percent;
        this.discounts[index].channel = this.channelsList.find((el) => el.id === this.discounts[index].discount_type_id.channel_id);
      } else {
        this.discounts[index].from = '';
        this.discounts[index].to = '';
        this.discounts[index].percent = '';
        this.discounts[index].channel = '';
        this.discounts[index].channel = undefined;
      }
    },

    pushDiscounts(prop, type) {
      const date_start = new Date(this.date_from).getTime();
      const date_end = new Date(this.date_to).getTime();

      if (this.modalName == 'project' && prop[0]?.order != null) prop.sort((a, b) => (a.order > b.order ? 1 : -1));
      prop.forEach((el, index) => {
        const dsc = this.discountTypes.data.find((type) => type.id === el.discount_type_id);
        //only push relevant discounts
        if (dsc) {
          const discount_from = new Date(el.from).getTime();
          const discount_to = new Date(el.to).getTime();
          if ((discount_from <= date_start && discount_to >= date_start) || (discount_from >= date_start && discount_from <= date_end))
            this.discounts.push({
              discount_type_id: dsc,
              from: el.from,
              to: el.to,
              percent: el.percent,
              fromType: type || this.type,
              channel: this.getDiscountChannel(el),
              id: el.id,
              order: el.order,
            });
        }
      });
    },

    hideModalDiscounts() {
      this.$refs['modal-discounts'].hide();
      this.clearFields();
    },
    addRowInModal() {
      this.discounts.push({
        discount_type_id: '',
        from: '',
        to: '',
        percent: '',
        channel: '',
        fromType: this.type,
        order: undefined,
      });
    },
    removeRow(data) {
      this.discounts.splice(data, 1);
    },

    async deleteRow(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('table.pomRecalculationWillBePerformed')}`, {
          title: this.$i18n.t('table.confirmDeleteDiscount'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-discount-modal',
        })
        .then((value) => {
          if (value) {
            //delete API
            const formData = this.discounts[id].id;
            this.$store.dispatch('DELETE_DISCOUNTS_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('alert.deleteDiscount'),
                });
                this.removeRow(id);
                this.$emit('refreshData');
                this.$emit('removeDiscount', formData);
              },
              handlerError: (errors) => {
                errorsHandler(errors, this.$notify);
              },
            });
          }
        })
        .catch(() => {});
    },

    getDiscountChannel(el) {
      const channel_id = this.discountTypes.data.find((type) => type.id === el.discount_type_id)?.channel_id;
      //get channel info if channel_id exist
      return channel_id ? this.channelsList.find((ch) => ch.id === channel_id) : '';
    },

    async Discounts() {
      const discountsWOparents = [];
      this.discounts.forEach((el) => {
        if (this.modalName == 'project' || (!el.id && el.fromType === this.type)) {
          discountsWOparents.push({
            discount_type_id: el.discount_type_id.id ? el.discount_type_id.id : this.discountTypes.data.find((type) => type.id === el.discount_type_id)?.id,
            from: el.from,
            to: el.to,
            percent: el.percent,
            fromType: el.fromType,
            order: null,
          });
        }
      });
      const posList = this.positions.reduce((obj, i) => {
        const value = parseFloat(i.value);
        return isNaN(value) ? obj : { ...obj, [i.name]: value };
      }, {});
      this.$emit('transferDiscounts', [discountsWOparents, posList]);
      this.hideModalDiscounts();
    },

    clearFields() {
      this.filterDateFrom = '';
      this.filterDateTo = '';
      this.discounts = [
        {
          discount_type_id: '',
          from: '',
          to: '',
          percent: '',
          channel: '',
          fromType: 'Project',
          order: undefined,
        },
      ];
      this.positions.forEach((element) => {
        element.value = '';
      });
    },

    checkIfValidAddThenEnter() {
      if (!this.$v.$invalid && this.isAllSDOrdersSet && this.percentsValid) this.Discounts();
    },

    returnDays(days_of_week_iso) {
      let tempString = '';
      days_of_week_iso.forEach((day, index) => {
        if (index !== 0) tempString += ', ';
        if (day == 1) return (tempString += this.$i18n.t('table.mon'));
        else if (day == 2) return (tempString += this.$i18n.t('table.tue'));
        else if (day == 3) return (tempString += this.$i18n.t('table.wed'));
        else if (day == 4) return (tempString += this.$i18n.t('table.thu'));
        else if (day == 5) return (tempString += this.$i18n.t('table.fri'));
        else if (day == 6) return (tempString += this.$i18n.t('table.sat'));
        else if (day == 7) return (tempString += this.$i18n.t('table.sun'));
      });
      return tempString;
    },
  },
};
</script>

<style lang="sass">
#modal-discounts > .modal-dialog
  max-width: 1200px
  width: calc(100vw - 32px)

.period > div
  display: flex
  gap: 0.5rem
  width: 100%

.btn-add-mr
  margin-right: calc(120px + 0.5rem)

#modal-discounts .modal-body,
#modal-discounts .modal-content
  position: static
</style>
